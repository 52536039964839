@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?s7uiwn');
  src:  url('../fonts/icomoon.eot?s7uiwn#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?s7uiwn') format('truetype'),
    url('../fonts/icomoon.woff?s7uiwn') format('woff'),
    url('../fonts/icomoon.svg?s7uiwn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\e907";
  color: #fff;
}
.icon-angle-down:before {
  content: "\e900";
}
.icon-lock:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-brand4:before {
  content: "\e904";
}
.icon-social4:before {
  content: "\e904";
}
.icon-twitter:before {
  content: "\e905";
}
.icon-brand5:before {
  content: "\e905";
}
.icon-tweet:before {
  content: "\e905";
}
.icon-social5:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e906";
}
.icon-brand6:before {
  content: "\e906";
}
.icon-social6:before {
  content: "\e906";
}